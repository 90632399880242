var App = {

    angular: angular.module('siteApp', []),

    init: function() {

        this.initValidation();
        this.initAlerts();
        this.initForms();
        this.initSelects();
        this.initDates();
    },

    initValidation: function() {

        $('form.form-validate').validate({

            highlight: function(element) {
                $(element).closest('.form-group').removeClass('has-success').addClass('has-error');
            },
            unhighlight: function(element) {
                $(element).closest('.form-group').removeClass('has-error').addClass('has-success');

                $(element).popover('destroy');
            },
            errorPlacement: function(error, element) {

                if (typeof $(element).data('bs.popover') == 'undefined' || $(element).data('bs.popover').getContent() != $(error).text()) {

                    $(element).popover({
                        content: $(error).text(),
                        placement: 'bottom'
                    });

                    $(element).popover('show');
                }
            },
            events: 'submit',
            selector: 'input[type!=submit], select, textarea',
            callback: function( elem, valid ) {
                if ( ! valid ) {
                    $( elem ).addClass('error');
                }
            }
        });
    },

    initAlerts: function() {

        $(".alert.alert-success").fadeTo(2500, 500).slideUp(500, function(){
            $(".alert.alert-success").alert('close');
        });

        $("#flash-overlay-modal").modal();
    },

    initForms: function() {

        $('form.dirty').areYouSure( {'silent':true} );
        $(window).on('beforeunload', function() {
            if ($('form.dirty').hasClass('dirty')) {
                return "Se lasci questa pagina le tue modifiche andranno perse!";
            }
        });
    },

    initSelects: function() {

        $('select.select2').select2();
    },

    initDates: function() {

        $("input.input-date").datetimepicker({
            lang: 'it',
            timepicker:false,
            format:'d/m/Y',
            scrollInput: false
        });
    }
};

