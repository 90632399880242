
App.angular.controller('AssociazioneController', function($scope, $http, $filter, $window) {

    $scope.associazione = document.getElementById('associazione') ? JSON.parse(document.getElementById('associazione').value) : 0;
    $scope.associazioniRicordate = localStorage.getItem('associazioni_ricordate') ? JSON.parse(localStorage.getItem('associazioni_ricordate')) : [];

    $scope.remember = $scope.associazioniRicordate.map(function (associazione) {return associazione.id}).indexOf($scope.associazione.id) > -1;

    $scope.toggleRicordaAssociazione =  function () {

        console.log($scope.associazione);

        var index = $scope.associazioniRicordate.map(function (associazione) {return associazione.id}).indexOf($scope.associazione.id);

        if(!$scope.remember) {

            if (index > -1) {

                $scope.associazioniRicordate.splice(index, 1);
            }

        } else {

            if (index == -1) {
                $scope.associazioniRicordate.push({
                    id: $scope.associazione.id,
                    name: $scope.associazione.name,
                    categoria: $scope.associazione.categoria ? $scope.associazione.categoria.name : ''
                })
            }
        }

        localStorage.setItem('associazioni_ricordate', JSON.stringify($scope.associazioniRicordate));
    };
});